import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Thanks for reaching out to me! I'll get to you as soon as we can.`}</p>
    <small>
  Note: you should receive a copy of the email as well. If you do not then it
  didn't work - feel free to drop me a line <a href="mailto:sas.projects@me.com">here</a>.
    </small>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      